/* Reviews should be hidden, more context in this ticket:
https://bigcommercecloud.atlassian.net/browse/WEBDEV-908?atlOrigin=eyJpIjoiNDRlYWQ2NDI2ZDRlNDMzOTg0NmI3YTA1ZTkwY2ViOWEiLCJwIjoiaiJ9 */

export const isAppReviewsTemporarilyHidden = true

const hideReviewApps = ['mailchimp', 'intuit mailchimp']

export const shouldAppReviewTemporarilyHidden = (appName: string | null | undefined) => {
	if (!appName) return false
	return hideReviewApps.includes(appName.toLocaleLowerCase())
}
